import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {FormatoAfiliacion} from '../models/formato-afiliacion';
import {Location} from '../models/location';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
    formatoAfiliacion: FormatoAfiliacion = new FormatoAfiliacion();
    location: Location = new Location();

  constructor(private afs: AngularFirestore) { }

    getFormatoAfiliacion() {
        return this.afs.collection('formatoAfiliacion').snapshotChanges().pipe(
            map(actions => actions.map(a => {
                this.formatoAfiliacion = a.payload.doc.data() as FormatoAfiliacion;
                this.formatoAfiliacion.id = a.payload.doc.id;
                return this.formatoAfiliacion;
            }))
        );
    }

    getCementerios() {
        return this.afs.collection('cementerios').snapshotChanges().pipe(
            map(actions => actions.map(a => {
                this.location = a.payload.doc.data() as Location;
                this.location.id = a.payload.doc.id;
                return this.location;
            }))
        );
    }
}
