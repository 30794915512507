export class Location {
    id?: string;
    position?: {
        lat: number;
        lng: number;
    };
    title?: string;
    options?: {
        animation: any;
    };
    label?: {
        color: any;
    };
}
