<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Detalles de los Servicios</h2>
            <ul>
                <li><a routerLink="/">Inicio</a></li>
                <li class="active">Detalles de los Servicios</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="../../../../assets/images/company/company-img.jpg" alt="company" />
                    </div>
                </div>
                <div class="col-lg-8">
                    <h3>SERVICIO FUNERARIO CON INHUMACIÓN</h3>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Ataúd de madera modelo Estándar.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Carroza.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Capilla ardiente.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Vehículo porta flores.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Trámites ante RENIEC.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Traslado local Velatorio y camposanto.</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Salón velatorio (según disponibilidad).</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Cargadores uniformados y/o vvhh de acompañamiento(12 psj).</p>
                    <p style="text-align: justify; ">&nbsp;&rsaquo; Tumba en cementerio privado (compartida) o Nicho de beneficencia o municipal.</p>
                </div>
            </div>
        </div>
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <h3>SERVICIO FUNERARIO CON CREMACIÓN</h3>
                    <p style="text-align: justify;">Ataúd de madera modelo Estándar.</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Capilla ardiente.</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Trámites ante RENIEC.</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Traslado local al velatorio y lugar de cremación.</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Salón velatorio </p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Cargadores uniformados y/o vvhh de acompañamiento(12 psj)</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Uso de horno crematorio.</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Necropsia y autorización (DISA).</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Notario (legalización).</p>
                    <p style="text-align: justify;">&nbsp;&rsaquo; Urna.</p>
                </div>
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="assets/images/23B.jpg" alt="Image">
                    </div>
                </div>


            </div>
        </div>
        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="assets/images/despedida.jpg" alt="Image">
                    </div>
                </div>

                <div class="col-lg-8">
                    <h3>REEMBOLSOS</h3>
                    <h5 class="m-0"><strong>¿Quiénes aplican al reembolso?</strong></h5>
                    <p style="text-align: justify">Los afiliados al <strong>FOSSEP</strong> que residan en lugares muy apartados, en localidades de provincias donde no hay agencias funerarias contratadas que cubran dicha zona, podrá emplear los servicios que brindan las agencias funerarias o sus similares en dichas zonas, pudiendo para tal efecto, ser reintegrados dichos gastos de sepelio, hasta por el monto máximo vigente fijado por el Consejo Directivo para el lugar. </p>
                    <h5 class="m-0"><strong>¿Cómo aplican al reembolso?</strong></h5>
                    <p style="text-align: justify">Los afiliados al <strong>FOSSEP</strong> deberán presentar los originales de las facturas o boletas de venta a nombre del <strong>FOSSEP</strong>, acompañados de una solicitud dirigida al Administrador en un plazo no mayor de <strong>180 días calendario</strong>, a partir de la fecha de deceso. </p>
                    <h5 class="m-0"><strong>IMPORTANTE:</strong></h5>
                    <p style="text-align: justify">El Reembolso en <strong>Lima</strong> solo corresponderá al uso de espacios, sepulturas o paquetes de cremación adquiridos en vida o en necesidad futura, el monto será establecido por el consejo directivo del <strong>FOSSEP</strong>. </p>
                    <p style="text-align: justify">En <strong>Lima</strong> el uso de agencias funerarias ajenas a las contratadas por el <strong>FOSSEP</strong>, no generarán reembolso por motivo alguno.</p>
                    <p style="text-align: justify">El <strong>FOSSEP</strong> previa evaluación de la situación podrá reintegrar los gastos por sepelio del titular y/o beneficiario que residan en el extranjero, hasta por el monto máximo, fijado por el Consejo Directivo.</p>
                    <p style="text-align: justify">Los servicios de <strong>inhumación o cremación</strong>, pueden ser parciales si el afiliado ya cuenta con sepultura o atención funeraria adquiridos con anterioridad.<strong> No hay reembolso alguno por dichos conceptos</strong></p>
                </div>
            </div>
        </div>
        <!--<div class="scrives-item-3 mt-4">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="social">
                        <ul class="social-link">
                            <li><a target="_blank" href="#"><i class="bx bxl-facebook"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-twitter"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-pinterest-alt"></i></a></li>
                            <li><a target="_blank" href="#"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="share">
                        <a href="#" target="_blank"><i class="bx bx-share-alt"></i> Share</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="services-img mb">
                    <img src="assets/images/solutions-details/1.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="services-img mb">
                    <img src="assets/images/solutions-details/2.png" alt="Image">
                </div>
            </div>

            <div class="col-12">
                <div class="services-details-text">
                    <h2>IT Management</h2>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum  not only five centuries, but also the leap into.</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc. this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a dictionary of over 200 Latin words, combined with a</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc generators on the Internet tend to repeat predefined chunks as necessary, making this the first generator dictionary of over 200 Latin words, combined with a.</p>
                </div>
            </div>
        </div>-->




    </div>
</section>
