import { Component, OnInit } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-informacion',
  templateUrl: './informacion.component.html',
  styleUrls: ['./informacion.component.scss']
})
export class InformacionComponent implements OnInit {
    safeSrc: SafeResourceUrl;
  constructor(private domSanitizer: DomSanitizer) {
      this.safeSrc = this.domSanitizer.bypassSecurityTrustResourceUrl('assets/pdf/AvisoImportanteFossep.pdf');
  }

  ngOnInit(): void {
  }

}
