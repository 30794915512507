<h2 mat-dialog-title>INFORMACIÓN IMPORTANTE...!!!</h2>
<mat-dialog-content class="mat-typography">
<!--    <iframe [src]="safeSrc" style="border:none; width:100%; height: 100vh; display:block;" ></iframe>-->
    <object  [data]="safeSrc" style="border:none; width:100%; height: 100vh;" type="application/pdf" width="100%" height="100vh">

    </object>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" mat-dialog-close><mat-icon class="mat-18">close</mat-icon>Cerrar</button>
</mat-dialog-actions>
