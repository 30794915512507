import { Component, OnInit } from '@angular/core';
import {PublicService} from '../../../services/public.service';
import {FormatoAfiliacion} from '../../../models/formato-afiliacion';

@Component({
  selector: 'app-case-studies-details',
  templateUrl: './case-studies-details.component.html',
  styleUrls: ['./case-studies-details.component.scss']
})
export class CaseStudiesDetailsComponent implements OnInit {
    listaFormatoAfiliacion: FormatoAfiliacion[] = [];
  constructor(private publicService: PublicService) {
      this.publicService.getFormatoAfiliacion().subscribe(
          (data) => {
              this.listaFormatoAfiliacion = data.sort((a, b) => (a.descripcion < b.descripcion ? -1 : 1));
          }
      );
  }

  ngOnInit(): void {
  }

}
