<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nosotros</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Nosotros</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>¿Quienes Somos?</span>
                        <h2>Fondo Solidario De Sepelio Del Ejército Del Perú</h2>
                        <p style="text-align: justify">Institución de apoyo solidario y sentido humanista, creado para atender los casos de fallecimiento de sus afiliados, presentandose como la mejor alternativa de auxilio mutuo de quienes lo conforman para afrontar de la mejor manera los difíciles momentos de un fallecimiento, buscando aminorar la carga emocional de la familia y otorgarle respaldo absoluto en la atención de su ser querido.</p>
                        <p style="text-align: justify">Asimismo, su constitución y desarrollo se hace con apego a las leyes y normatividad aplicables a las sociedades sin fines de lucro.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="../../../../assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/historia.jpg" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Historia</h2>
                    <p style="text-align: justify">Los casos de fallecimiento de algún miembro del Ejército del Perú, actualmente no tienen una cobertura adecuada ni oportuna, se encuentra vigente la Escala de los Gastos de Sepelio para el personal de las FFAA. Y PNP del D.S.N° 140-93 del mes de Setiembre del año 1993 que establece sumas variables de acuerdo a la categoría (militar o civil) y rango; esta va desde los S/.667.00 para el personal civil auxiliar hasta S/.1,500.00 en caso de Oficiales Generales y Almirantes, es solo para los miembros titulares que se otorga a los deudos después de que estos realicen los trámites y gestiones correspondientes, lo que dura varias semanas para hacerse efectivo.</p>
                    <p style="text-align: justify">Es una Asociación Civil sin fines de lucro y su funcionamiento y control se encuentra en su estatuto correspondiente así como su Reglamento Interno de funcionamiento.</p>

                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Organización</h2>
                        <p style="text-align: justify">Constituye el máximo nivel de decisión del FOSSEP, así como tal, a través  del Presidente del Consejo Directivo, da cuenta de sus actos a la  Asamblea General de Asociados. El periodo de duración del Consejo  Directivo es de un (1) año. El nombramiento de sus integrantes es de acuerdo a lo dispuesto en el Estatuto.</p>
                        <p style="text-align: justify">El Consejo de Vigilancia constituye el órgano máximo de control administrativo de las decisiones del Consejo Directivo, debe hacer cumplir las normas del presente Reglamento. Contará con autonomía administrativa, siendo sus decisiones independientes del Consejo Directivo o de la Administración del FOSSEP, efectuarán las funciones de auditoria  previa, recurrente y posterior en todas las áreas de la Administración.</p>
                        <p style="text-align: justify">Lo constituye la Administración del FOSSEP, a cargo de un Administrador, profesional en Administración de empresas, Contabilidad, Economía o profesiones afines; será propuesto por el Presidente del Consejo Directivo y aprobado por el Consejo Directivo.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="../../../../assets/images/organigrama.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>



