import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './components/common/preloader/preloader.component';
import {NavbarComponent} from './components/common/navbar/navbar.component';
import {FooterComponent} from './components/common/footer/footer.component';
import {HomeTwoComponent} from './components/pages/home-two/home-two.component';
import {AboutComponent} from './components/pages/about/about.component';
import {SolutionsComponent} from './components/pages/solutions/solutions.component';
import {SolutionsDetailsComponent} from './components/pages/solutions-details/solutions-details.component';
import {CaseStudiesComponent} from './components/pages/case-studies/case-studies.component';
import {CaseStudiesDetailsComponent} from './components/pages/case-studies-details/case-studies-details.component';
import {GalleryComponent} from './components/pages/gallery/gallery.component';
import {SignInComponent} from './components/pages/sign-in/sign-in.component';
import {ErrorComponent} from './components/pages/error/error.component';
import {ContactComponent} from './components/pages/contact/contact.component';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {GoogleMapsModule} from '@angular/google-maps';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatInputModule} from '@angular/material/input';
import { InformacionComponent } from './components/pages/informacion/informacion.component';
import {MatDialogModule} from '@angular/material/dialog';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NgxWhastappButtonModule} from 'ngx-whatsapp-button';
import { VideosComponent } from './components/pages/home-two/videos/videos.component';
import {MatButtonModule} from "@angular/material/button";

@NgModule({
    declarations: [
        AppComponent,
        PreloaderComponent,
        NavbarComponent,
        FooterComponent,
        HomeTwoComponent,
        AboutComponent,
        SolutionsComponent,
        SolutionsDetailsComponent,
        CaseStudiesComponent,
        CaseStudiesDetailsComponent,
        GalleryComponent,
        SignInComponent,
        ErrorComponent,
        ContactComponent,
        InformacionComponent,
        VideosComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AngularFireAuthModule,
        GoogleMapsModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatSnackBarModule,
        MatInputModule,
        MatDialogModule,
        PdfViewerModule,
        NgxWhastappButtonModule,
        MatButtonModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
