import { Injectable } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private angularFireAuth: AngularFireAuth) { }

    loginWithEmail(email: string, password: string) {
        return this.angularFireAuth.signInWithEmailAndPassword(email, password);
    }

    logOut() {
        return this.angularFireAuth.signOut();
    }
}
