<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Proceso de Afiliación</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Proceso de Afiliación</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="choose-area ptb-100">
    <div class="container">
        <!--<div class="section-title">
            <h2>We Achieved People’s Trust by Our Great Service</h2>
        </div>-->

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/21B.jpg" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <ul class="accordion">
                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-friends"></i>
                                </div>
                                <a class="accordion-title active" href="javascript:void(0)"><i class="bx bx-plus"></i> ¿Quienes pueden afiliarse?</a>
                                <p class="accordion-content show">Todo el personal que labora en el ejército del Perú (militar y/o civil), en situación de actividad, retiro y/o cesante; que perciban un sueldo o pensión renovable por parte del estado, AFP, y/o caja pensión.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-friends"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>¿A quienes puedo afiliar?</a>
                                <p class="accordion-content">Pueden inscribir a su cónyuge, hijos y padres, que ninguno pase de 75 años, en el año que realizan la afiliación.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-friends"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>¿Requisitos para solicitar el Servicio?</a>
                                <p class="accordion-content"> Estar afiliado o haber inscrito a su familiar en la hoja de datos familiares. Cumplir con el periodo de carencia. Estar al día en sus aportaciones o regularizarlas.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-clock"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>¿Ques es el periódo de carencia?</a>
                                <p class="accordion-content">MUERTE NATURAL: Cuatro (04) meses de descuento por planilla. MUERTE ACCIDENTAL: Un (01) mes de descuento por planilla.</p>
                            </li>

                            <li class="accordion-item">
                                <div class="icon">
                                    <i class="flaticon-info"></i>
                                </div>
                                <a class="accordion-title" href="javascript:void(0)"><i class="bx bx-plus"></i>Causas de Desafiliación</a>
                                <p class="accordion-content">Dejar de aportar tres (03) meses consecutivos y/o cinco (05) meses alternados durante un (01) año , automáticamente se realiza la desafiliación, sin derecho alguno de devolución de aportes efectuados por el titular con anterioridad a esta situación.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Afiliación</h2>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>¿Quienes pueden afiliarse?</h3>
                        <p>Todo el personal que labora en el ejército del Perú (militar y/o civil).</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>¿A quienes puedo afiliar?</h3>
                        <p>A su cónyuge, hijos y padres, que ninguno pase de 75 años, en el año que realizan la afiliación.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Familiares Adicionales</h3>
                        <p>Hermano, nieto, hijastro y conviviente.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Aporte Mensual</h3>
                        <p >Grupo 1: s/ 15.00</p>
                        <p >Grupo 2: s/ 22.00</p>
                        <p >Hijos mayores de 28 AÑOS: S/15.00.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Aporte Mensual de Afiliación</h2>
        </div>

        <div class="tab quote-list-tab">
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-pricing">
                                <div class="pricing-top-heading">
                                    <h3>Grupo N°1</h3>
                                </div>
                                <span>S/ 15 <sub>/M</sub></span>
                                <ul>
                                    <li><i class="bx bx-badge-check"></i> Titular.</li>
                                    <li><i class="bx bx-badge-check"></i> Cónyuge.</li>
                                    <li><i class="bx bx-badge-check"></i> Hijos.</li>
                                    <li><i class="bx bx-badge-check"></i> Padres menores de 65 años de edad.</li>
                                    <li><i class="bx bx-badge-check"></i> <strong>Hijos mayores de 28 años: s/15.00 por cada uno.</strong></li>

                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6 offset-sm-3 offset-lg-0">
                            <div class="single-pricing">
                                <div class="pricing-top-heading">
                                    <h3>Grupo N°2</h3>
                                </div>
                                <span>S/ 22 <sub>/M</sub></span>
                                <ul>
                                    <li><i class="bx bx-badge-check"></i> Titular.</li>
                                    <li><i class="bx bx-badge-check"></i> Cónyuge.</li>
                                    <li><i class="bx bx-badge-check"></i> Hijos.</li>
                                    <li><i class="bx bx-badge-check"></i> Padres mayores de 65 años de edad.</li>
                                    <li><i class="bx bx-badge-check"></i> <strong>Hijos mayores de 28 años: s/15.00 por cada uno.</strong></li>
                                </ul>

                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-pricing">
                                <div class="pricing-top-heading">
                                    <h3>Familiares</h3>
                                    <p>(Menores de 55 Años)</p>
                                </div>
                                <span>S/ 15 <sub>/M</sub></span>
                                <ul>
                                    <li><i class="bx bx-badge-check"></i> Hermano.</li>
                                    <li><i class="bx bx-badge-check"></i> Nieta.</li>
                                    <li><i class="bx bx-badge-check"></i> Hijastro.</li>
                                    <li><i class="bx bx-badge-check"></i> conviviente.</li>
                                </ul>
                                <strong class="popular">&nbsp;Adicionales</strong>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-pricing">
                                <div class="pricing-top-heading">
                                    <h3>Familiares</h3>
                                    <p>(Mayores de 55 Años)</p>
                                </div>
                                <span>S/ 22 <sub>/M</sub></span>
                                <ul>
                                    <li><i class="bx bx-badge-check"></i> Hermano.</li>
                                    <li><i class="bx bx-badge-check"></i> Nieta.</li>
                                    <li><i class="bx bx-badge-check"></i> Hijastro.</li>
                                    <li><i class="bx bx-badge-check"></i> conviviente.</li>
                                </ul>
                                <strong class="popular">&nbsp;Adicionales</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


