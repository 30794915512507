<app-navbar></app-navbar>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Servicios</h2>
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Servicios</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Servicios</h2>
            <p>Un Seguro de Sepelio para ti y tu familia, que les brinda tranquilidad para cuándo más lo necesiten.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/inhumacion.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Servicio Funerario con Inhumación</h3>
                        <p>Comprende la atención funeraria, el ataúd, el espacio en cementerio.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/cremacion.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Servicio Funerario con Cremación</h3>
                        <p>Comprende la atención funeraria, el ataúd, la cremación y entrega de cenizas.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/reembolso.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Reembolsos</h3>
                        <p>Los afiliados al FOSSEP que residan en lugares muy apartados, en provincias donde no hay agencias funerarias.</p>
                        <a routerLink="/solutions-details" class="line-bnt">Quieres saber más?</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6">
                 <div class="single-service">
                     <div class="service-img">
                         <img src="assets/images/service/s4.png" alt="service" />
                     </div>

                     <div class="service-content">
                         <h3>IT Management</h3>
                         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                         <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                     </div>
                 </div>
             </div>

             <div class="col-lg-4 col-sm-6">
                 <div class="single-service">
                     <div class="service-img">
                         <img src="assets/images/service/s5.png" alt="service" />
                     </div>

                     <div class="service-content">
                         <h3> Cyber Security</h3>
                         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                         <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                     </div>
                 </div>
             </div>

             <div class="col-lg-4 col-sm-6">
                 <div class="single-service">
                     <div class="service-img">
                         <img src="assets/images/service/s6.png" alt="service" />
                     </div>

                     <div class="service-content">
                         <h3> Wireless Connectivity</h3>
                         <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                         <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                     </div>
                 </div>
             </div>-->
        </div>
    </div>
</section>
