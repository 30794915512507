import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../services/authentication.service';
import {Router} from '@angular/router';
import {User} from '../../../models/user';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {PersonaService} from '../../../services/persona.service';
import {Titular} from '../../../models/titular';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    loginForm: FormGroup;
    user: User = new User();
    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar,
                private personaService: PersonaService) {
    }

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.maxLength(9)]],
            password: ['', [Validators.required, Validators.maxLength(8)]]
        });
    }

    login() {
        this.authenticationService.loginWithEmail((this.user.email + '@fossep.com.pe'), this.user.password).then(
            (data) => {
                this.personaService.isAdminByCip(Number(data.user.uid)).subscribe(
                    (value) => {
                        if (value !== undefined) {
                            if (value) {
                                this.router.navigate(['intranet/informacionAdministrador']);
                            } else {
                                this.router.navigate(['intranet/informacionAfiliado']);
                            }
                            sessionStorage.setItem('cip', data.user.uid);
                        } else {
                            console.log('No existe en Base de datos...!!!');
                            this.openSnackBar(environment.desNotFoundDatainDB, '');
                            this.router.navigate(['']);
                            sessionStorage.setItem('cip', null);
                        }
                    });
            }
        ).catch((error) => {
            if (error.code === environment.codeAuthUserNotFound) {
                this.openSnackBar(environment.desAuthUserNotFound, '');
            }
        });
    }

    openSnackBar(mensaje: string, accion: string) {
        this.snackBar.open(mensaje, accion, {
            duration: 3000,
            verticalPosition: 'top'
        });
    }

}
