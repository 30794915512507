<app-navbar></app-navbar>
<div class="page-title-area">
    <!--<div class="container">
        <div class="page-title-content">&lt;!&ndash;
            <h2>Información del Afiliado</h2>&ndash;&gt;
            <ul>
                <li><a routerLink="/">Inicio </a></li>
                <li class="active">Ver Descuentos</li>
            </ul>
        </div>
    </div>-->

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="signup-area ptb-100">
    <div class="container">
        <div class="signup-form">
            <div class="section-tittle text-center">
                <h2>Ingresar al Sistema</h2>
            </div>
            <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="loginForm.valid && login()" novalidate>
                <div class="row">

                    <div class="col-md-12 col-sm-12 text-center">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>CIP</mat-label>
                                <input matInput formControlName="email" [(ngModel)]="user.email">
                                <mat-icon matSuffix class="secondary-text">payment</mat-icon>
                                <mat-error *ngIf="loginForm.get('email').hasError('required')">Numero de CIP es requerido!</mat-error>
                                <mat-error *ngIf="loginForm.get('email').hasError('maxlength')">Superaste el maximo de caracteres ...!!!</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-md-12 col-sm-12 text-center">
                        <div class="form-group">
                            <mat-form-field appearance="outline">
                                <mat-label>DNI</mat-label>
                                <input matInput type="password" formControlName="password" [(ngModel)]="user.password">
                                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                                <mat-error *ngIf="loginForm.get('email').hasError('required')">Numero de DNI es requerido!</mat-error>
                                <mat-error *ngIf="loginForm.get('email').hasError('maxlength')">Superaste el maximo de caracteres ...!!!</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <button type="submit" class="box-btn signup-btn"  [disabled]="loginForm.invalid">INGRESAR</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
